import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/[lang]": [~2],
		"/[lang]/action": [~4],
		"/[lang]/booking/form": [~5],
		"/[lang]/booking/payment": [~6],
		"/[lang]/complains": [7],
		"/[lang]/confirm-email": [~8],
		"/[lang]/contact": [9],
		"/[lang]/find-my-driver": [~10],
		"/[lang]/general-info": [11],
		"/[lang]/how-to-book": [12],
		"/[lang]/landing/weekend": [13],
		"/[lang]/login": [14],
		"/[lang]/lost-and-founds": [~15],
		"/[lang]/projects": [16],
		"/[lang]/register": [~17],
		"/[lang]/request-estimate": [~18],
		"/[lang]/reset-password": [19],
		"/[lang]/services": [20],
		"/[lang]/success": [21],
		"/[lang]/user/[slug]": [~22],
		"/[lang]/[islandSlug]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';